











































import Vue from 'vue';
import { RealtimeMarket } from '@/data/dto/realtime-market.dto';

export default Vue.extend({
  name: 'new-image',
  props: {
    title: {
      type: String,
      required: true,
    },
    program: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      image: null,
      isLoading: false,
      markets: [],
      alternativeName: null,
      marketSelected: '',
      isOnError: false,
    };
  },
  computed: {
    isInputsValid(): boolean {
      return this.image !== null && this.marketSelected !== '';
    },
    programLength() {
      return this.title.length;
    },
  },
  async beforeMount(): Promise<void> {
    await this.$store.dispatch('getRealtimeMarketsConfig')
      .then((res) => {
        this.markets = res.map((realtimeMarket: RealtimeMarket) => realtimeMarket.id);
      })
      .catch(() => {
        this.$notification.notify({
          message: 'Não foi possível completar a operação. Por favor verifique a sua conexão ou tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      });
  },
  methods: {
    notifyError(message: string) {
      this.$notification.notify({
        message,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
    },
    notifySuccess(message: string) {
      this.$notification.notify({
        message,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success',
        timeout: 2000,
      });
    },
    async submit() {
      try {
        this.isLoading = true;

        const payload = {
          title: this.title,
          program: this.program,
          marketSelected: this.marketSelected,
          image: this.image,
          alternativeName: this.alternativeName || null,
        };
        await this.$store.dispatch('addImage', payload);

        this.isLoading = false;

        this.$notification.notify({
          message: 'Programa criado com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 2000,
        });
        this.$emit('close');
      } catch (e) {
        this.isLoading = false;

        console.log(e);
        this.$notification.notify({
          message: 'Não foi possivel executar a operação. Por favor tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      }
    },
    removeImage() {
      this.image = null;
    },
    onChange(e: any) {
      const { files } = e.target;
      this.createFile(files[0]);
    },
    createFile(file: File) {
      if (!file.type.match('image.*')) {
        this.notifyError('Formato de arquivo inválido. Selecione uma imagem');
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.image = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
});
