















import Vue from 'vue';

export default Vue.extend({
  props: {
    label: String,
  },
  methods: {
    handleClick(evt: any) {
      this.$emit('click', evt);
    },
  },
});
