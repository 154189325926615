






























import { Component, Vue } from 'vue-property-decorator';
import { Program } from '@/data/entity/program.entity';
import ImageSliderCard from '@/views/ProgramsUpload/components/ImageSliderCard.vue';

import AddButton from '@/components/AddButton.vue';
import NewImageModal from '@/views/ProgramsUpload/components/NewProgramModal.vue';
import EditImageModal from '@/views/ProgramsUpload/components/EditProgramModal.vue';

@Component({
  components: {
    ImageSliderCard,
    AddButton,
  },
})
export default class ProgramsDetail extends Vue {
  programs: Program[] = []

  isOnError = false

  get isLoading() {
    return this.$store.state.system.isLoading;
  }

  get currentProgram() {
    const [program] = this.programs;
    return program;
  }

  async beforeMount() {
    await this.reloadTable();
  }

  async reloadTable() {
    this.$store.dispatch('getOneProgram', this.$route.params.key)
      .then((res: Program[]) => {
        this.programs = res;
      })
      .catch(() => {
        this.isOnError = true;
      });
  }

  notifyError() {
    this.$notification.notify({
      message: 'Não foi possivel executar a operação. Por favor tente novamente.',
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'danger',
      timeout: 3000,
    });
  }

  notifySuccess(message: string) {
    this.$notification.notify({
      message,
      icon: 'tim-icons icon-bell-55',
      horizontalAlign: 'center',
      verticalAlign: 'top',
      type: 'success',
      timeout: 2000,
    });
  }

  getMarketName(market: string) {
    return market === '*' ? 'Todas' : market;
  }

  editImage(currentMarket: string,
    currentImageURL: string,
    currentImageUUID: string,
    programTitle: string,
    programKey: string,
    currentAlternativeName: string) {
    this.$modal.show(
      EditImageModal,
      {
        currentMarket,
        currentImageURL,
        currentImageUUID,
        programTitle,
        programKey,
        currentAlternativeName,
      },
      { adaptive: true, height: 'auto' },
      {
        'before-close': this.reloadTable,
      },
    );
  }

  removeImage(uuid: string, market: string) {
    this.$modal.show('dialog', {
      title: `Você deseja deletar o programa para praça ${market}`,
      buttons: [
        {
          title: 'Cancelar',
          handler: () => {
            this.$modal.hide('dialog');
          },
        },
        {
          title: 'Sim',
          handler: async () => {
            this.$modal.hide('dialog');
            await this.$store.dispatch('removeImage', uuid)
              .then(() => {
                this.reloadTable();
                this.notifySuccess('Programa removido com sucesso');
              })
              .catch(() => this.notifyError());
          },
        },
      ],
    });
  }

  newImage() {
    this.$modal.show(
      NewImageModal,
      { title: this.currentProgram.title, program: this.currentProgram.program },
      { adaptive: true, height: 'auto' },
      {
        'before-close': this.reloadTable,
      },
    );
  }
}
