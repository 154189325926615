<template>
  <card class="card" bodyClasses="card-body-no-padding">
    <div class="body">
      <div class="image-slide">
        <div v-lazy-container="{ selector: 'img', error: errorImage , loading: loadingImage }">
          <img :data-src="image" style="width: 180px">
        </div>
      </div>
    </div>
    <div class="title">
      <p>{{ title }}</p>
    </div>
    <div class="slider-actions">
      <div class="actions">
        <i class="tim-icons icon-pencil" @click="$emit('edit')"></i>
        <i class="tim-icons icon-trash-simple" @click="$emit('remove')"></i>
      </div>
    </div>
  </card>
</template>

<script>
import Vue from 'vue';
import Card from '@/components/Cards/Card.vue';
import errorImage from '@/assets/image_error.png';
import loadingImage from '@/assets/card_loading.gif';

export default Vue.extend({
  name: 'image-slider-card',
  components: {
    Card,
  },
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    url: {
      type: String,
    },
  },
  methods: {
    isActive(index) {
      return this.currentPosition === index;
    },
    getMarketName(marketId) {
      return marketId === '*' ? 'Default' : marketId;
    },
  },
  data() {
    return {
      errorImage,
      loadingImage,
      currentPosition: 0,
    };
  },
});
</script>

<style lang="scss" scoped>

.card{
  cursor: pointer;

  .body{
    min-height: 266px;
    min-width: 180px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .title{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center
  }

  .slider-actions{
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    transition: height 0.2s ease;

    .actions{
      background-color: rgb(232, 62, 140);
      display: none;
      justify-content: space-evenly;
      justify-items: center;
      align-items: center;
      height: 100%;

      i{
        color: white;
        //cursor: pointer;
      }
    }
  }

  &:hover{
    .body .image-slide {
      .prev, .next{
        display: block;
      }
    }
    .slider-actions{
      height: 50px;

      .actions{
        display: flex;
      }
    }
  }
}
</style>
