var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[(_vm.programs.length > 0)?_c('div',[_c('div',{staticClass:"header"},[_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'programs'}}},[_c('i',{staticClass:"tim-icons icon-minimal-left"}),_c('p',[_vm._v(_vm._s(_vm.currentProgram.title))])])],1),_c('div',{staticClass:"scrollable"},_vm._l((_vm.programs),function(program){return _c('image-slider-card',{key:program.id,staticClass:"card",attrs:{"image":program.url,"title":_vm.getMarketName(program.marketId)},on:{"edit":function($event){return _vm.editImage(
          program.marketId,
          program.url,
          program.id,
          program.title,
          program.program,
          program.alternativeName
        )},"remove":function($event){return _vm.removeImage(program.id, program.marketId)}}})}),1),_c('add-button',{staticClass:"add-button",attrs:{"label":"Novo Programa"},on:{"click":_vm.newImage}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }