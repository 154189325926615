





















































import Vue from 'vue';

export default Vue.extend({
  name: 'edit-program',
  props: {
    currentMarket: {
      type: String,
      default: '',
    },
    currentImageURL: {
      type: String,
      default: '',
    },
    currentImageUUID: {
      type: String,
      default: '',
    },
    programTitle: {
      type: String,
      default: '',
      required: true,
    },
    programKey: {
      type: String,
      default: '',
      required: true,
    },
    currentAlternativeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: null,
      alternativeName: this.currentAlternativeName,
      isLoading: false,
    };
  },
  computed: {
    marketName(): string {
      return this.currentMarket === '*' ? 'Todas' : this.currentMarket;
    },
    programLength(): number {
      return this.programTitle.length;
    },
  },
  methods: {
    notifyError(message: string) {
      this.$notification.notify({
        message,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'danger',
        timeout: 3000,
      });
    },
    notifySuccess(message: string) {
      this.$notification.notify({
        message,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success',
        timeout: 2000,
      });
    },
    async submit() {
      try {
        this.isLoading = true;

        const payload = {
          title: this.programTitle,
          program: this.programKey,
          marketSelected: this.currentMarket,
          alternativeName: this.alternativeName || null,
          image: this.image,
          currentImageURL: this.currentImageURL,
        };
        await this.$store.dispatch('addImage', payload);

        await this.$store.dispatch('removeImage', this.currentImageUUID);

        this.isLoading = false;

        this.$notification.notify({
          message: 'Programa alterado com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 2000,
        });
        this.$emit('close');
      } catch (e) {
        this.isLoading = false;

        console.log(e);
        this.$notification.notify({
          message: 'Não foi possivel executar a operação. Por favor tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger',
          timeout: 3000,
        });
      }
    },
    removeImage() {
      this.image = null;
    },
    onChange(e: any) {
      const { files } = e.target;
      this.createFile(files[0]);
    },
    createFile(file: File) {
      if (!file.type.match('image.*')) {
        this.notifyError('Formato de arquivo inválido. Selecione uma imagem');
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
});
